<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link> &gt;
                    <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">{{ organizationName }}</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0 mt-0">
                        <v-toolbar dense flat color="green darken-2" dark>
                            <v-toolbar-title>Custom Hostname</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <p class="mb-0 mt-2">
                                A custom hostname allows you to direct customers to your own domain for a seamless transition between your main website and your customer website.
                            </p>
                            <!-- TODO: fee for custom hostname? -->
                            <!-- <p class="mb-0 mt-2">
                            </p> -->

                            <template v-if="hostname">
                            <p class="mb-0 pb-0 mt-6">
                                {{ hostname }}
                                <v-btn icon color="green darken-2" @click="dialogEditHostname = true">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"></font-awesome-icon>
                                </v-btn>
                            </p>
                            <!-- <p class="mb-0 pb-0">
                                <TextLink :href="viewHostnameURL" target="_blank">{{ viewHostnameURL }}</TextLink>
                            </p> -->
                            </template>

                            <template v-if="!hostname">
                            <p class="mb-0 pb-0 mt-6">
                                Take your branding to the next level with a custom hostname.
                            </p>
                            <p class="mb-0 pb-0">
                                <v-btn icon color="green darken-2" @click="dialogEditHostname = true">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"></font-awesome-icon>
                                </v-btn>
                            </p>
                            </template>
                        </v-card-text>
                    </v-card>
                    <v-dialog v-model="dialogEditHostname" max-width="600">
                        <v-card class="pa-0" max-width="600">
                            <v-toolbar dense flat color="white">
                                <v-toolbar-title class="green--text text--darken-2">Set Custom Hostname</v-toolbar-title>
                            </v-toolbar>
                            <!-- <v-divider class="mx-5 mb-10"></v-divider> -->
                            <v-card-text>
                                <p>Changes to the custom hostname may break existing links. If you change your hostname, you should also set up a redirect from the prior hostname to the new one for customers that may have it bookmarked.</p>
                            </v-card-text>
                            <v-form @submit.prevent="editHostname" @keyup.enter.native="editHostname" class="px-5 mt-5">
                                <v-text-field
                                    ref="editableHostnameInput"
                                    v-model="editableHostname"
                                    label="Hostname"
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-form>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="green darken-2 white--text" @click="editHostname" :disabled="!isEditHostnameFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditHostname = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        // TextLink,
    },
    data: () => ({
        organization: null,
        hostname: null,
        error: null,

        dialogEditHostname: false,
        editableHostname: null,
        submitFormTimestamp: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        organizationName() {
            return this.organization?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.organization !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isEditHostnameFormComplete() {
            return this.editableHostname;
        },
    },
    watch: {
        dialogEditHostname(newValue) {
            if (newValue) {
                this.editableHostname = this.brandprofile;
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('editableHostnameInput'); }, 1);
                });
            }
        },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async loadSetting() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadSetting: true });
                const response = await this.$client.organization(this.$route.params.organizationId).setting.get({ name: 'hostname' });
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.hostname = response.content;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadSetting: false });
            }
        },
        async editHostname() {
            try {
                if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitFormTimestamp = Date.now();
                this.error = false;
                this.$store.commit('loading', { editHostname: true });
                const response = await this.$client.organization(this.$route.params.organizationId).setting.edit({ name: 'hostname' }, { content: this.editableHostname });
                console.log(`editHostname: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.dialogEditHostname = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.loadSetting();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit hostname' });
                }
            } catch (err) {
                console.error('failed to edit Hostname', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit hostname' });
            } finally {
                this.$store.commit('loading', { editHostname: false });
            }
        },
    },
    mounted() {
        this.loadOrganization();
        this.loadSetting();
    },
};
</script>
